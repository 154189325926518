import React from "react"
import styled from "styled-components"
import {
  SectionLabelOne,
  BreakpointTablet,
  BreakpointMobile,
} from "../Layout/GlobalStyle"
import { Link } from "gatsby"
import HerbResultItem from "../HerbResultItem"
const Container = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
  .herb-of-the-day-wrapper {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-gap: 20px;
    overflow: hidden;
    height: 200px;
  }

  .herb-information {
    display: flex;
    flex-direction: column;
    justify-content: "space-between";
  }

  @media only screen and (max-width: ${BreakpointTablet + "px"}) {
    .herb-of-the-day-wrapper {
      display: grid;
      grid-template-columns: 200px 1fr;
      grid-gap: 20px;
    }
  }

  @media only screen and (max-width: ${BreakpointMobile + "px"}) {
    .herb-of-the-day-wrapper {
      display: grid;
      grid-template-columns: 100px 1fr;
      grid-gap: 20px;
      height: 100px;
    }
  }
`

const StyledH3 = styled.h3`
  margin-top: 0px;
  font-family: var(--Font1);
  font-size: x-large;
  margin-bottom: 0px;
  color: #222220;

  :hover {
    color: var(--Color2);
    text-decoration: underline;
  }
`

const StyledParagraph = styled.p`
  font-size: 13px;
  font-family: var(--Font2);
  color: #4d4d4d;
  margin-top: 0px;
  height: inherit;
`

const Tile = styled.div`
  width: 200px;
  height: 200px;
  background-size: cover;

  ${props =>
    props.background ? `background-image: url(${props.background});` : ""};
  @media only screen and (max-width: ${BreakpointMobile + "px"}) {
    width: 100px;
    height: 100px;
  }
`

const HerbOfTheDay = props => {
  let { herb } = props
  let { pictures, slug, name } = herb
  console.log(herb, 'this is herb')
  return (
    <Container>
      <SectionLabelOne paddingBottom={1}>
        {herb.name ? "Herb of the day" : "Loading Herb of the day"}
      </SectionLabelOne>

      <HerbResultItem forceLoadPicture={herb && herb._id ? true : false} item={herb} />
    </Container>
  )
}

HerbOfTheDay.defaultProps = {
  herb: {
    name: "",
    slug: "",
    pictures: [],
  },
}

export default HerbOfTheDay
