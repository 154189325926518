import React, {useState, useEffect} from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import HerbResultItem from '../HerbResultItem'
import { SectionLabelOne } from '../Layout/GlobalStyle'
import { getRecentlyViewedHerbs } from '../../services/herb.services'
import LoadingSpinner2 from '../common/LoadingContainer/LoadingSpinner2';
const defaultLimit = 5

function PopularHerbs() {
  const [herbs, setHerbs] = useState([])



  const [loading, setLoading] = useState(false)
  const [limit, setLimit] = useState(defaultLimit)
  const [fetchingMore, setFetchingMore] = useState(false)
  const [count, setCount] = useState(0)


  // START OF USE EFFECTS

    useEffect(() => {
      let isSubscribed = true;
      if (isSubscribed) {
          loadHerbs()
      }
      return () => {
          isSubscribed = false;
      }
  }, [])


    useEffect(() => {
      let isSubscribed = true;
      if (isSubscribed) {
          fetchMoreHerbs()
      }
      return () => {
          isSubscribed = false;
      }
  }, [limit !== defaultLimit])

  // END OF USE EFFECTS

  // START OF API CALLS

  const loadHerbs = () => {
    setLoading(true)
    getRecentlyViewedHerbs(`?limit=${limit}`)
      .then(res => {
        if(res.status === 200){
          setLoading(false)
          setHerbs(res.data.herbs)
          setCount(res.data.count)
        }
      })
      .catch(err => {
        setLoading(false)

        throw err
      })
  }

  const fetchMoreHerbs = () => {
    setFetchingMore(true)
    getRecentlyViewedHerbs(`?limit=${limit}`)
      .then(res => {
        if(res.status === 200){
          setFetchingMore(false)

          setHerbs(res.data.herbs)
          setCount(res.data.count)
        }
      })
      .catch(err => {
        setFetchingMore(false)

        throw err
      })
  }

  // END OF API CALLS


//     const data = useStaticQuery(graphql`
//     query getHerbsByRecentlyViewed {
//       allMongodbHerbsHerbs(sort: {order: DESC, fields: lastTimeViewed}, limit: 5, filter: {draft: {eq: false}, safeToPublish: {eq: true}}) {
//         edges {
//           node {
//             id
//             latinName
//             slug
//             name
//             mongodb_id
//             description
//             pictures {
//               src
//               taken_by
//               alt_text
//             }
//           }
//         }
//     } 
//   }
// `)


    // START OF RENDER ITEMS

    let renderedItems = herbs.map((item, index) => {
        return(<HerbResultItem key={index} item={item} />)
    })

    // END OF RENDER ITEMS

    return (
        <div>
            <SectionLabelOne paddingBottom={1}>
                Popular Herbs
            </SectionLabelOne>
            {loading? <LoadingSpinner2  color={"#05AA97"} /> :  renderedItems}
        </div>
    )
}

export default PopularHerbs
