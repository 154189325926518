import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { getTopRemedies } from '../../services/remedy.services';
import { RemedyColumnTwo, RemedyGridList, RemedyGridListWrapper } from '../Ailment';
import LoadingSpinner2 from '../common/LoadingContainer/LoadingSpinner2';
import { PageHeaderTypeTwo, SectionLabelOne } from '../Layout/GlobalStyle';
import RemedyItemTypeTwo from '../common/RemedyItemTypeTwo';



const Container = styled.div`
    padding-top: 25px;
    padding-bottom: 25px;
`

 function TopRemediesSection() {
    const [remedies, setRemedies] = useState([])
    const [loadingRemedies, setLoadingRemedies] = useState(false)


    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            loadTopRemedies()
        }
        return () => {
            isSubscribed = false;
        }
    }, [])


    const loadTopRemedies = () => {
        setLoadingRemedies(true)
        getTopRemedies()
            .then(res => {
                if(res.status === 200){
                    setLoadingRemedies(false)

                    setRemedies(res.data.remedies)
                }
            })
            .catch(err => {
                setLoadingRemedies(false)

                throw err
            })
    }

    const renderRemedies = remedies.map((item, index) => <RemedyItemTypeTwo showAilments={true} itemIndex={index} key={index} item={item} />)

    return (
        <Container>

        <RemedyColumnTwo>
                <SectionLabelOne paddingBottom={1}>
                    {loadingRemedies ? `Looking for remedies `
                        : !loadingRemedies && remedies.length ?
                            `Top Natural Remedies`
                            : !loadingRemedies && !remedies ? `No remedies found` : ''
                    }
                </SectionLabelOne>
                <RemedyGridListWrapper>

                <RemedyGridList>
                

                    {loadingRemedies ? <LoadingSpinner2 color={"#05AA97"} />
                        : renderRemedies}
                  
                  </RemedyGridList>
                  </RemedyGridListWrapper>

        </RemedyColumnTwo>
        </Container>
    )
}

export default TopRemediesSection
