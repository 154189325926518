import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import PopularHerbs from "../PopularHerbs"
import HerbOfTheDay from "../HerbOfTheDay"
import { getHerbs } from "../../services/herb.services"
import styled from "styled-components"
import {
  CallToActionButton,
  BreakpointTablet,
  BreakpointDesktop,
} from "../Layout/GlobalStyle"
import LoadingContainer from "../common/LoadingContainer"
import TopRemediesSection from "../TopRemediesSection/TopRemediesSection"
import SmallerProductsSection from "../ProductsSection/SmallerProductsSection"
import ProductsSectionGrid from "../ProductsSection/ProductsSectionGrid"
import Advertisement from '../Advertisement'
import PopularHerbsStatic from "../PopularHerbs/PopularHerbsStatic"
const Container = styled.div`
  display: grid;
  padding-bottom: 100px;
  width: 100%;

  margin: auto;
  grid-gap: 100px;
  grid-template-columns: 1fr 0.5fr;
  .show-more-herbs-wrapper {
    margin-top: 50px;
  }

  @media only screen and (max-width: ${BreakpointDesktop - 150 + "px"}) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`

const LandingColumnOne = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
  width: inherit;

  @media only screen and (max-width: ${BreakpointDesktop - 150 + "px"}) {
    grid-gap: 20px;
  }
`

const LandingColumnTwo = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
`

const Landing = props => {
  const [loading, setLoading] = useState(false)
  const [featuredHerb, setFeaturedHerb] = useState({
    name: "",
    _id: "",
    slug: "",
  })

  const loadData = () => {
    // setLoading(true)
    getHerbs("?featuredOnDay=true")
      .then(res => {
        if (res.status >= 200) {
          // console.log(res.data, 'this is res.data')
          if (res.data[0]) {
            setFeaturedHerb(res.data[0])
          }
          // setLoading(false)
        }
      })
      .catch(err => {
        // setLoading(false)

        throw err
      })
  }

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      loadData()
    }
    return () => {
      isSubscribed = false
    }
  }, [])

  return (
    <Container>
      {loading ? (
        <LoadingContainer />
      ) : (
        <>
          <LandingColumnOne>
            <HerbOfTheDay herb={featuredHerb} />
            <ProductsSectionGrid forHerb={true} herb={featuredHerb} />

            <PopularHerbsStatic />
            <div className="show-more-herbs-wrapper">
              <Link to={"/herbs"}>
                <CallToActionButton>See all of our herbs</CallToActionButton>
              </Link>
            </div>
          </LandingColumnOne>
          <LandingColumnTwo>

            <TopRemediesSection />
            <Advertisement maxWidth={"600px"} />

          </LandingColumnTwo>
        </>
      )}

      {/* <CustomerChat /> */}
    </Container>
  )
}

export default Landing
