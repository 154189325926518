

import React from "react"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Landing from "../components/Landing"

const MainPage = () => (
  <Layout seoProps={{title: "Find Natural Remedies for Illnesses with the herbs you love"}}>
    <Landing />
  </Layout>
)

export default MainPage
